import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMedium,
  faGithub,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import moment from "moment";

const Footer = () => {
  const getYear = moment().year();
  const footerLinks = [
    {
      icon: faMedium,
      title: "Medium",
      link: "https://medium.com/@husseinmubarak500",
    },
    {
      icon: faGithub,
      title: "Github",
      link: "https://github.com/Kiddo-prog",
    },
    {
      icon: faTwitter,
      title: "Twitter",
      link: "https://twitter.com/codinkage",
    },
    {
      icon: faLinkedin,
      title: "Linkedin",
      link: "https://www.linkedin.com/in/hussein-mubarak-3b00b11b2/",
    },
  ];
  return (
    <Container className="d-flex flex-column-reverse justify-content-center align-items-center gap-3 border-top pt-5 pb-5">
      <div
        className="font-italic text-center small text-muted"
        style={{ fontSize: "17px" }}
      >
        &copy; Copyright CodinKage {getYear}
      </div>

      <div className="d-flex gap-4">
        {footerLinks.map((item, index) => (
          <Link
            key={index}
            to={item.link}
            target="_blank"
            className="footer-links"
          >
            <FontAwesomeIcon
              icon={item.icon}
              className="text-white p-3 rounded-circle footer-item"
            />
          </Link>
        ))}
      </div>
    </Container>
  );
};

export default Footer;
