import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'; 
import { setDatePicker } from '../../redux/slices/posts';

import "react-datepicker/dist/react-datepicker.css";
import "./date.css";

const FilterPostByDate = () => {
    const dispatch = useDispatch(); 
    const [selectedDate, setSelectedDate] = useState(new Date());

    const renderMonthContent = (month, shortMonth, longMonth, day) => {
      const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
  
      return <span title={tooltipText}>{shortMonth}</span>;
    };

    const handleDatePicker = (date) => { 
      setSelectedDate(date); 
      dispatch(setDatePicker(date)); 
    }

    return (
      <div className='d-flex justify-content-end align-items-center gap-2'>
        <FontAwesomeIcon icon={faCalendarAlt} />
        <DatePicker
          selected={selectedDate}
          renderMonthText={renderMonthContent}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          onChange={(date) => handleDatePicker(date)}
          // withPortal
        />
      </div>
    );
}

export default FilterPostByDate