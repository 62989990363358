import moment from 'moment';

export const generateWordsPerMinutes = (content) => {
    let WPM = 250;
    let estimatedTime = Math.ceil(content.length / WPM);
    return `${estimatedTime} min read`;
};

export const formatDate = (date) => {
    let formattedDate = moment(date).format("MMM, Do YYYY");
    return formattedDate;
}

export const sortRecentPost = (data) => { 
    return data?.sort((a, b) => Date.parse(b?.created) - Date.parse(a?.created));
}
