import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import Posts from './Posts/posts'
import FilterPostByDate from '../components/DateFilter/FilterPostByDate'

function Home() {
  return (
    <>
      <Header
        title={"Hussein Mubarak"}
        subTitle={"Welcome to my blog"}
        headerImage={'/assets/home-page.jpg'} />
      <Container>
        <Row className='d-flex justify-content-center'>
          <FilterPostByDate />
          <Col md={10} lg={8} xl={7}>
            <Posts />
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Home