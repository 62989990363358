import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedDate: null
}
export const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setDatePicker: (state, action) => { 
            state.selectedDate = action.payload 
        }
    }
});

export const getDatePicker = (state) => state.posts.selectedDate;
export const {
    setDatePicker
} = postSlice.actions;

export default postSlice.reducer;