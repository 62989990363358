import sanityClient from "../client";
import axios from "axios";
import { MailURL } from "../utils/constants";
class ApiServices {
    static async fetchPost(cb) {
        sanityClient.fetch(`*[_type == "blog"]{
            _id,
            title,
            slug,
            description,
            created,
            content,
            cover   
          }`)
            .then((response) => {
                if (response != null) {
                    cb(response, null);
                }
            })
            .catch((error) => {
                cb(null, error);
            });
    }

    static async fetchSinglePost(slug, cb) {
        sanityClient.fetch(`*[_type == "blog" && slug.current == "${slug}"]{
            _id,
            title,
            slug,
            created,
            content,
            cover, 
          }`)
            .then((response) => {
                if (response != null) {
                    cb(response, null);
                }
            })
            .catch((error) => {
                cb(null, error);
            });
    }

    static async fetchPostByDate(currentDate, nextCurrentDate, cb) {        
        sanityClient.fetch(`*[_type == "blog" && created >= '${currentDate}' && created < '${nextCurrentDate}']{
            _id,
            title,
            slug,
            description,
            created,
            content,
            cover     
        }`)
        .then((response) => {
            if (response != null) {
                cb(response, null);
            }
        })
        .catch((error) => {
            cb(null, error);
        });
    }

    static async sendMessage(requestBody, cb) {
        axios.post(`${MailURL}/send_mail`, requestBody)
            .then((response) => {
                if (response != null) {
                    cb(response, null);
                }
            })
            .catch((error) => {
                cb(null, error);
            })
    }
}

export default ApiServices; 