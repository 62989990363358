import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages';
import About from './pages/About';
import Contact from './pages/Contact/contact';
import Post from './pages/Posts/post';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/blog/:slug' element={<Post />} />
      <Route path='/contact' element={<Contact />} />
    </Routes>
  )
}

export default App; 
