import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import animationData from "../lotties/email_sent.json";

const EmailSent = (props) => {
  const { show, handleClose } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Lottie options={defaultOptions} height={200} width={200} />
              <p className="email-sent">Your email has been delivered</p>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default EmailSent;
