import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useParams } from "react-router-dom";
import remarkGfm from 'remark-gfm';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { urlFor } from "../../helpers/imageBuilder";
import ApiServices from "../../http/api_services";
import { formatDate } from "../../utils/gen";

const Post = () => {
    const { slug } = useParams();
    const [post, setPost] = useState({});

    useEffect(() => {
        ApiServices.fetchSinglePost(slug, (response, error) => {
            if (error) {
                return;
            }
            if (response) {
                const data = response[0];
                setPost(data);
            }
        })
    }, [slug]);

    let imageUrl = post?.cover?.asset['_ref'];
    let imageCover = urlFor(imageUrl);
    let date = formatDate(post?.created);

    return (
        <div>
            <Header
                title={post?.title}
                subTitle={`Posted on ${date}`}
                headerImage={imageCover ?? '/default_blog.jpg'}
                isPost={true}
                content={post?.content}
                />

            <Container className="px-4 px-lg-5">
                <Row className='d-flex justify-content-center'>
                    <Col md={10} lg={8} xl={7}>
                        <ReactMarkdown children={post?.content} remarkPlugins={[remarkGfm]} className="post-content" />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div >
    )
}

export default Post; 