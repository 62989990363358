import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';

function NavigationBar() {
  const navItems = [
    { id: 1, to: '/', name: 'Home' },
    { id: 2, to: '/about', name: 'About' },
    { id: 3, to: '/contact', name: 'Contact' }
  ];

  return (
    <>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3" id="mainNav">
          <Container className="px-4 px-lg-5">
            <Navbar.Brand href="#" className='navbar-brand'>CodinKage</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  CodinKage
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 px-3 nav-item">
                  {navItems.map((link) => (
                    <Nav.Link href={link.to} key={link.id} className="px-lg-3 py-3 py-lg-4 nav-link">
                      {link.name}
                    </Nav.Link>
                  ))}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavigationBar;